import React from "react";
import worker from "workerize-loader!./worker"; // eslint-disable-line

let { getCurrentAmount, getPayDates } = worker();

let numberFmt = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

function RealtimeSal({ salary, payDate, payBeforeWeekends }) {
  let [currentAmount, setCurrentAmount] = React.useState();
  let [payDates, setPayDates] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (!payDates.length) {
        setPayDates(await getPayDates(payDate, payBeforeWeekends));
      }
    })();
  }, [payDates, payBeforeWeekends, payDate]);

  React.useEffect(() => {
    let stop = false;
    let req;

    let calc = async () => {
      setCurrentAmount(
        await getCurrentAmount(salary, payDate, payBeforeWeekends)
      );
      if (stop) return;
      req = requestAnimationFrame(calc);
    };
    calc();

    return () => {
      stop = true;
      cancelAnimationFrame(req);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div title={payDates.join("\n")}>
      <code>
        {typeof currentAmount === "number" && numberFmt.format(currentAmount)}
      </code>
    </div>
  );
}

function App() {
  return (
    <div style={{ padding: 8 }}>
      <RealtimeSal salary={107_000_000} payDate={1} payBeforeWeekends={false} />
      <RealtimeSal salary={42_000_000} payDate={19} payBeforeWeekends />
    </div>
  );
}

export default App;
